import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import { E164Number } from "libphonenumber-js/core";
import { ComponentProps, CSSProperties, FC, useState } from "react";
import PhoneInput, { Country } from "react-phone-number-input";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import "react-phone-number-input/style.css";
import {
  textFieldHeightStyles,
  textFieldStyles,
  textInputSubtextStyle,
} from "@src/deprecatedDesignSystem/components/TextField";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  value: E164Number | undefined;
  label?: string;
  labelTextProps?: Partial<ComponentProps<typeof Text>>;
  onChange: (value: E164Number | undefined) => void;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  error?: boolean;
  height?: "40px" | "32px";
  subtext?: string;
  required?: boolean;
};

const PhoneNumberInput: FC<Props> = ({
  value,
  label = "Mobile Phone Number",
  labelTextProps,
  onChange,
  className,
  disabled,
  style,
  error,
  height = "32px",
  subtext,
  required,
}) => {
  const [countryCode, setCountryCode] = useState<Country | undefined>("US");
  return (
    <div className={`${className} ${css(styles.container)}`} style={style}>
      <div className={css(styles.label)}>
        <Text
          type={"P3"}
          fontWeight={"SemiBold"}
          color={deprecatedColors.onBackground}
          {...labelTextProps}
        >
          {label}
          {required && "*"}
        </Text>
      </div>
      <div className={css(styles.inputContainer)}>
        <PhoneInput
          placeholder={countryCode === "US" && "(201) 555-5555"}
          defaultCountry="US"
          value={value}
          numberInputProps={{
            className: css(
              textFieldStyles.input,
              textFieldStyles.default,
              error && textFieldStyles.error,
              textFieldHeightStyles[height],
            ),
            style: {
              height,
            },
          }}
          onCountryChange={(country) => setCountryCode(country)}
          onChange={onChange}
          disabled={disabled}
          limitMaxLength
        />
        {disabled && (
          <DeprecatedIcon
            type="lock"
            color={deprecatedColors.onDisabled}
            styleDeclaration={styles.lock}
          />
        )}
      </div>
      {subtext && (
        <Text
          type="P3"
          fontWeight="Medium"
          color={error ? deprecatedTones.red9 : deprecatedTones.gray7}
          styleDeclaration={textInputSubtextStyle[height]}
        >
          {subtext}
        </Text>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  label: {
    marginBottom: 4,
  },
  inputContainer: {
    marginLeft: 4,
    position: "relative",
  },
  lock: {
    position: "absolute",
    right: "12px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  error: {
    border: `1px solid ${deprecatedColors.error}`,
  },
});

export default PhoneNumberInput;
