import { gql } from "@apollo/client";
import { InitializeBillingModal } from "@components/layout/topNav/InitializeBillingModal";
import SetActionPermissions from "@components/layout/topNav/SetActionPermissions";
import CreateOrgModal from "@components/modals/orgManagement/CreateOrgModal";
import UpdateOrgModal from "@components/modals/orgManagement/UpdateOrgModal";
import Spacer from "@components/ui/Spacer";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedButton from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import { useIsStaff } from "@hooks/useIsStaff";
import { useModal } from "@hooks/useModal";
import useUser from "@hooks/useUser";
import * as Popover from "@radix-ui/react-popover";
import ConfirmationModal from "@src/components/modals/ConfirmationModal";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import { useBillingInitialized } from "@src/hooks/billing/useBillingInitialized";
import { StyleSheet, css } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC } from "react";
import { useResetDemoOrgMutation } from "./AdminTools.generated";
import { launchConfetti } from "@src/utils/confetti";
import { useToast } from "@src/hooks/useToast";
import GlobeIcon from "@src/ui/icons/12px/globe";
import { Button } from "@src/ui/button";

const AdminTools: FC = () => {
  const { isStaff } = useIsStaff();
  if (!isStaff) {
    return null;
  }
  return <NavOrg />;
};

const NavOrg: React.FC = () => {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button size="icon" variant="outline">
          <GlobeIcon />
        </Button>
      </Popover.Trigger>
      <AdminMenu />
    </Popover.Root>
  );
};

const AdminMenu: FC = () => {
  const billingInitialized = useBillingInitialized();
  const { showModal } = useModal();
  const { user } = useUser();
  const { addToast } = useToast();
  const [resetDemoOrg, { loading: resetLoading }] = useResetDemoOrgMutation({
    onCompleted: () => {
      launchConfetti();
      addToast({
        iconType: "refresh",
        message:
          "Demo org reset begun. Please check back in 1 hour to make sure everything looks right.",
      });
    },
  });
  return (
    <AnimatePresence>
      <Popover.Content
        asChild
        sideOffset={4}
        align="end"
        className={css(styles.dropdownContainer)}
      >
        <motion.div
          initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
          animate={{
            scaleY: 1,
            opacity: 1,
            transition: { type: "spring", duration: 0.2 },
          }}
          style={{ originY: 0, originX: 1 }}
        >
          <Text type="P1" fontWeight="Medium" style={{ marginLeft: 4 }}>
            Org ID: {user?.org!.id}
          </Text>
          <Spacer size={12} />
          <AutoLayout
            direction="vertical"
            spaceBetweenItems={12}
            marginBottom={12}
          >
            <AutoLayout spaceBetweenItems={12}>
              <DeprecatedButton
                onClick={() => {
                  showModal(<CreateOrgModal />);
                }}
                variant="Primary"
                text="Add org"
                leftIcon="plus"
              />
              <DeprecatedButton
                onClick={() => {
                  showModal(<UpdateOrgModal />);
                }}
                variant="Primary"
                text={`Update ${user?.org?.name}`}
                leftIcon="edit"
              />
            </AutoLayout>
            <AutoLayout spaceBetweenItems={12}>
              {!billingInitialized && (
                <DeprecatedButton
                  onClick={() => {
                    showModal(<InitializeBillingModal />);
                  }}
                  variant="Primary"
                  text={"Initialize Billing"}
                  leftIcon="credit-card"
                />
              )}
              {user?.org?.isDemoOrg && (
                <DeprecatedButton
                  onClick={() => {
                    showModal(
                      <ConfirmationModal
                        title="Reset demo org"
                        text="Are you sure you want to reset your demo org? This can take up to two hours to complete."
                        confirmCopy="Yes, reset my demo org"
                        onConfirm={() => {
                          resetDemoOrg();
                        }}
                        confirmLoading={resetLoading}
                      />,
                    );
                  }}
                  variant="Primary"
                  text={"Reset demo org"}
                  leftIcon="refresh"
                />
              )}
            </AutoLayout>
          </AutoLayout>
          <SetActionPermissions />
        </motion.div>
      </Popover.Content>
    </AnimatePresence>
  );
};

export default AdminTools;
gql`
  mutation resetDemoOrg {
    resetDemoOrg {
      success
    }
  }
`;

const styles = StyleSheet.create({
  logoContainer: {
    backgroundColor: deprecatedColors.background,
    color: deprecatedTones.gray7,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: "100%",
    minWidth: 32,
    height: 32,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ":hover": {
      boxShadow: `0 0 0 4px ${deprecatedTones.gray4Alpha}`,
    },
  },
  dropdownContainer: {
    borderRadius: "8px",
    backgroundColor: deprecatedColors.background,
    boxShadow: shadows.dropdownShadow,
    width: 500,
    padding: 12,
    border: "none",
    display: "block",
    fontSize: "14px",
    lineHeight: "24px",
    margin: "0",
    outline: "none",
    textOverflow: "ellipsis",
    fontWeight: 400,
  },
  pickerItems: {
    padding: 4,
    height: 240,
    marginTop: 12,
    overflowY: "auto",
    cursor: "pointer",
  },
  pickerItem: {
    borderBottom: `1px solid ${deprecatedColors.border}`,
    padding: 4,
    fontSize: 13,
    ":hover": {
      backgroundColor: deprecatedColors.surface,
    },
  },
  selected: {
    backgroundColor: deprecatedColors.surface,
  },
  deactivated: {
    color: deprecatedColors.error,
  },
  header: {
    margin: "4px 0 12px 0",
  },
  adminMode: {
    marginBottom: 12,
  },
  adminModeToggle: {
    marginLeft: 4,
  },
  tooltip: {
    position: "absolute",
    top: 37,
    left: -36,
  },
});
