import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  input: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserMutationResult', success: boolean, user?: { __typename?: 'Employee', id: number, email?: string | null, userType: Types.UserType, jobTitle?: string | null, phoneNumber?: string | null, language: Language, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }> } | null, error?: { __typename?: 'Error', code: string, message?: string | null } | null } };

export type PersonDetailDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type PersonDetailDetailsQuery = { __typename?: 'Query', Employee?: { __typename?: 'Employee', id: number, userType: Types.UserType, email?: string | null, jobTitle?: string | null, language: Language, phoneNumber?: string | null, roles: Array<{ __typename?: 'Role', id: number, name: string }>, locations: Array<{ __typename?: 'Location', id: number, name: string }>, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, startDate?: string | null } | null } | null };

export type PersonDetailDetailsFragment = { __typename?: 'Employee', id: number, userType: Types.UserType, email?: string | null, jobTitle?: string | null, language: Language, phoneNumber?: string | null, roles: Array<{ __typename?: 'Role', id: number, name: string }>, locations: Array<{ __typename?: 'Location', id: number, name: string }>, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, startDate?: string | null } | null };

export const PersonDetailDetailsFragmentDoc = gql`
    fragment PersonDetailDetails on Employee {
  id
  userType
  email
  roles {
    id
    name
  }
  locations {
    id
    name
  }
  jobTitle
  language
  phoneNumber
  email
  hrisEmployee {
    id
    remoteHrisId
    startDate
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    user {
      id
      email
      userType
      jobTitle
      locations {
        id
        name
      }
      phoneNumber
      language
      roles {
        id
        name
      }
    }
    success
    error {
      code
      message
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const PersonDetailDetailsDocument = gql`
    query PersonDetailDetails($id: Int!) {
  Employee: AdminEmployee(id: $id) {
    ...PersonDetailDetails
  }
}
    ${PersonDetailDetailsFragmentDoc}`;

/**
 * __usePersonDetailDetailsQuery__
 *
 * To run a query within a React component, call `usePersonDetailDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonDetailDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonDetailDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonDetailDetailsQuery(baseOptions: Apollo.QueryHookOptions<PersonDetailDetailsQuery, PersonDetailDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonDetailDetailsQuery, PersonDetailDetailsQueryVariables>(PersonDetailDetailsDocument, options);
      }
export function usePersonDetailDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonDetailDetailsQuery, PersonDetailDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonDetailDetailsQuery, PersonDetailDetailsQueryVariables>(PersonDetailDetailsDocument, options);
        }
export type PersonDetailDetailsQueryHookResult = ReturnType<typeof usePersonDetailDetailsQuery>;
export type PersonDetailDetailsLazyQueryHookResult = ReturnType<typeof usePersonDetailDetailsLazyQuery>;
export type PersonDetailDetailsQueryResult = Apollo.QueryResult<PersonDetailDetailsQuery, PersonDetailDetailsQueryVariables>;