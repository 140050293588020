import React, { FC, useState } from "react";
import Modal from "@src/components/modals/Modal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { gql } from "graphql-request";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { useModal } from "@src/hooks/useModal";
import { launchConfetti } from "@src/utils/confetti";
import { useInitializeBillingMutation } from "./InitializeBillingModal.generated";
import {
  AddressInput,
  GraphqlOperations,
  UserType,
} from "@src/types.generated";
import AddressField from "@src/deprecatedDesignSystem/components/AddressField";
import useUser from "@src/hooks/useUser";
import Text from "@ui/text";
import PhoneNumberInput from "@src/components/ui/PhoneNumberInput";
import { E164Number } from "libphonenumber-js/types";

export const InitializeBillingModal: FC = () => {
  const { user } = useUser();
  const [legalName, setLegalName] = useState(user?.org?.name || "");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState<AddressInput | undefined>();
  const { closeModal } = useModal();
  const [initializeBilling, { loading }] = useInitializeBillingMutation({
    refetchQueries: [GraphqlOperations.Query.BillingBanner],
    onCompleted: (data) => {
      if (data.initializeBilling.success) {
        closeModal();
        launchConfetti();
      }
    },
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhonNumber] = useState<E164Number | undefined>(
    undefined,
  );
  const [jobTitle, setJobTitle] = useState("");
  const isValid = legalName && email && address;
  return (
    <Modal
      confirmButtonProps={{
        disabled: !isValid,
        loading: loading,
        copy: "Initialize",
        onClick: () => {
          if (!isValid) {
            return;
          }
          initializeBilling({
            variables: {
              input: {
                legalName,
                email: email || "",
                address,
                billingContact: phoneNumber
                  ? {
                      firstName,
                      lastName,
                      phoneNumber,
                      language: "en",
                      jobTitle,
                      locationIds: [],
                      roleIds: [],
                      userType: UserType.Admin,
                      email,
                    }
                  : undefined,
              },
            },
          });
        },
      }}
    >
      <AutoLayout
        flex={1}
        alignSelf="stretch"
        alignmentVertical="center"
        paddingVertical={32}
        paddingHorizontal={24}
        direction="vertical"
        spaceBetweenItems={12}
        width={600}
      >
        <Text type="P3" fontWeight="SemiBold">
          Company Info
        </Text>
        <TextField
          label="Legal name"
          text={legalName}
          onTextChange={(v) => setLegalName(v)}
        />
        <AddressField inputLabel="Address" onAddressChange={setAddress} />
        <Text type="P3" fontWeight="SemiBold" marginTop={12}>
          Billing Contact Info
        </Text>
        <AutoLayout alignSelf="stretch" spaceBetweenItems={12}>
          <TextField
            label="First Name"
            text={firstName}
            onTextChange={(v) => setFirstName(v)}
          />
          <TextField
            label="Last Name"
            text={lastName}
            onTextChange={(v) => setLastName(v)}
          />
        </AutoLayout>
        <AutoLayout alignSelf="stretch" spaceBetweenItems={12}>
          <TextField
            label="Billing Email"
            text={email}
            onTextChange={(v) => setEmail(v)}
          />
          <TextField
            label="Job Title"
            text={jobTitle}
            onTextChange={(v) => setJobTitle(v)}
          />
        </AutoLayout>
        <PhoneNumberInput
          label="Phone Number"
          value={phoneNumber}
          onChange={(v) => setPhonNumber(v)}
        />
      </AutoLayout>
    </Modal>
  );
};

gql`
  mutation InitializeBilling($input: InitializeBillingInput!) {
    initializeBilling(input: $input) {
      success
    }
  }
`;
