import { useUpdateMyOrgMutation } from "@components/layout/topNav/OrgSwitcher.generated";
import Modal from "@components/modals/Modal";
import { useCreateOrgMutation } from "@components/modals/orgManagement/CreateOrgModal.generated";
import OrgForm from "@components/modals/orgManagement/OrgForm";
import { useOrgFormState } from "@components/modals/orgManagement/useOrgFormState";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import { FC } from "react";

const CreateOrgModal: FC = () => {
  const state = useOrgFormState();
  const [updateMyOrg] = useUpdateMyOrgMutation({
    onCompleted: () => {
      window.location.reload();
    },
  });
  const [createOrg, { loading }] = useCreateOrgMutation({
    onCompleted: (res) => {
      if (res.createOrg.success && res.createOrg.org) {
        updateMyOrg({ variables: { orgId: res.createOrg.org?.id } });
      }
    },
  });
  const { name, deactivatedAt, startedAt, seatsExpected, timezone, isTrial } =
    state;
  return (
    <Modal
      title="Create Org"
      style={{ width: 460 }}
      confirmButtonProps={{
        copy: `Create Org`,
        loading,
        onClick: () => {
          createOrg({
            variables: {
              input: {
                name,
                seatsExpected,
                deactivatedAt: deactivatedAt
                  ? DateTime.fromJSDate(deactivatedAt).toISO()
                  : null,
                startedAt: startedAt
                  ? DateTime.fromJSDate(startedAt).toISO()
                  : null,
                timezone,
                isTrial,
              },
            },
          });
        },
      }}
    >
      <OrgForm {...state} creating />
    </Modal>
  );
};

export default CreateOrgModal;

gql`
  mutation CreateOrg($input: OrgInput!) {
    createOrg(input: $input) {
      success
      org {
        id
        name
      }
    }
  }
`;
