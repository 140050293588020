import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Link from "@src/deprecatedDesignSystem/components/Link";
import PersonAvatar from "@src/deprecatedDesignSystem/components/PersonAvatar";
import Text from "@ui/text";
import useUser from "@hooks/useUser";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import useLanguage from "@src/hooks/useLanguage";
import { UserType } from "@src/types.generated";
import { useTranslation } from "@src/utils/translationSets";
import { StyleSheet, css } from "aphrodite";
import { motion } from "framer-motion";
import React, { useState } from "react";

const MY_PROFILE_ENGLISH = "My Profile";
const LOGOUT_ENGLISH = "Logout";

export const SettingsButton: React.FC = () => {
  const language = useLanguage();
  const myProfileTranslation = useTranslation(
    MY_PROFILE_ENGLISH,
    language || "en",
  );
  const logoutTranslation = useTranslation(LOGOUT_ENGLISH, language || "en");
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const isTrainee = !user || user.userType === UserType.Employee;
  if (!user) return null;
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <div className={css(styles.container)}>
          <PersonAvatar person={user} size="36px" />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        sideOffset={4}
        align="end"
        className={css(styles.dropdownContainer)}
        asChild
      >
        <motion.div
          initial={{ scaleX: 1, scaleY: 0.6, opacity: 0 }}
          animate={{
            scaleY: 1,
            opacity: 1,
            transition: { type: "spring", duration: 0.2 },
          }}
          style={{ originY: 0, originX: 1, padding: "6px 0px" }}
        >
          {!isTrainee ? (
            <>
              <Link
                href={{
                  pathname: "/people/[id]",
                  query: { id: user.id.toString() },
                }}
                onClick={() => setOpen(false)}
                styleDeclaration={styles.dropdownOptionContainer}
              >
                <DeprecatedIcon
                  type="user-circle"
                  color={deprecatedTones.gray10}
                  style={{ marginRight: 8, marginLeft: 4 }}
                />
                <Text
                  type="P1"
                  color={deprecatedTones.black}
                  style={{ userSelect: "none" }}
                >
                  {myProfileTranslation.text || MY_PROFILE_ENGLISH}
                </Text>
              </Link>
              <HorizontalDivider style={{ marginTop: 4, marginBottom: 4 }} />
            </>
          ) : null}
          <Link
            href={{
              pathname: "/logout",
            }}
            onClick={() => setOpen(false)}
            styleDeclaration={styles.dropdownOptionContainer}
          >
            <DeprecatedIcon
              type="log-out"
              color={deprecatedTones.gray10}
              style={{ marginRight: 8, marginLeft: 4 }}
            />
            <Text
              type="P1"
              color={deprecatedTones.black}
              style={{ userSelect: "none" }}
            >
              {logoutTranslation.text || LOGOUT_ENGLISH}
            </Text>
          </Link>
        </motion.div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: deprecatedTones.blue1,
    color: deprecatedTones.blue5,
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: "100%",
    textDecoration: "none",
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      boxShadow: `${deprecatedTones.gray1} 0 0 0 4px`,
    },
    ":selected": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  link: {
    display: "block",
    cursor: "pointer",
    padding: "4px 16px",
    backgroundColor: deprecatedColors.background,
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  dropdownContainer: {
    zIndex: 100,
    display: "block",
    width: 180,
    paddingTop: 4,
    paddingBottom: 4,
    outline: "none",
    border: "none",
    borderRadius: "8px",
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.dropdownShadow,
  },
  dropdownOptionContainer: {
    display: "flex",
    alignItems: "center",
    height: 40,
    marginRight: 4,
    marginLeft: 4,
    paddingRight: 12,
    borderRadius: 8,
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
});

export default SettingsButton;
